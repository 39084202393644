import { FC, memo, MouseEvent, SyntheticEvent } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { ButtonFPot } from '@components/shared/buttons/button-fpot/ButtonFPot';
import { IconButton } from '@components/shared/buttons/icon-button/IconButton';
import { useIsMobile } from '@contexts/mobile-detector-provider';
import { IProductOptions } from '@types';

import classes from './BuyButton.module.scss';

const PostcardModal = dynamic(() =>
  import('@components/modals/postcard-modal/PostcardModal').then((mod) => mod.PostcardModal)
);

interface IBuyButtonProps {
  onSubmit: (e: SyntheticEvent | MouseEvent, message?: string) => void;
  addToCartHandler?: (ev: SyntheticEvent | MouseEvent, productOptions?: IProductOptions) => void;
  catalogCategoryProductsGroup?: string;
  displayName: string;
  isInCart?: boolean;
}

const BuyButton: FC<IBuyButtonProps> = ({
  onSubmit,
  catalogCategoryProductsGroup,
  displayName,
  isInCart,
  addToCartHandler
}) => {
  const isMobile = useIsMobile();

  return catalogCategoryProductsGroup === 'postcards' ? (
    <PostcardModal onSubmit={onSubmit} preventOpenModal={isInCart} labelPostcard={displayName} classNameWrapper={classes.postCardWrapper}>
      {isMobile ? (
        <ButtonFPot
          label={isInCart ? 'В кошику' : 'Купити'}
          className={clsx(classes.buyButton, classes.mobileBtn, { [classes.buyButtonInCart]: isInCart })}
        />
      ) : (
        <IconButton
          className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
          ariaLabel="Додати до кошику"
          icon={isInCart ? 'cartChecked' : 'cartFilled'}
        />
      )}
    </PostcardModal>
  ) : (
    <>
      {isMobile ? (
        <ButtonFPot
          label={isInCart ? 'В кошику' : 'Купити'}
          onClick={addToCartHandler}
          className={clsx(classes.buyButton, classes.mobileBtn, { [classes.buyButtonInCart]: isInCart })}
        />
      ) : (
        <IconButton
          className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
          onClick={addToCartHandler}
          ariaLabel="Додати до кошику"
          icon={isInCart ? 'cartChecked' : 'cartFilled'}
        />
      )}
    </>
  );
};

function areEqual(prevProps: IBuyButtonProps, nextProps: IBuyButtonProps) {
  return (
    prevProps.catalogCategoryProductsGroup === nextProps.catalogCategoryProductsGroup &&
    prevProps.displayName === nextProps.displayName &&
    prevProps.isInCart === nextProps.isInCart
  );
}

const BuyButtonMemo = memo(BuyButton, areEqual);

export { BuyButtonMemo as BuyButton };
